import { PipeTransform, Pipe } from '@angular/core';

@Pipe ({
    name: 'textshort'
})
export class TextShortPipe implements PipeTransform {
    transform(value: string): string {
        if (value === null) {
            value = '';
        }
        if (value.length > 20) {
            return value.substr(0, 20) + ' ...';
        }
        return value;
    }
}
