import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(value: any, filterString: string, propName: any): any {
    console.log('propName: ', propName);

    if (value.length === 0) {
      return value;
    }

    const resultArray = [];
    for (const item of value) {
      // console.log('item: ', item);

      let st = '';
      // st = item['team'].toLowerCase() + item['COMMENT'].toLowerCase() + item['custgroupName'].toLowerCase() + item['STATUS'].toLowerCase() ;
      if (item['team']) {
        st += item['team'].toLowerCase();
      }
      if (item['COMMENT']) {
        st += item['COMMENT'].toLowerCase();
      }
      if (item['custgroupName']) {
        st += item['custgroupName'].toLowerCase();
      }
      if (item['STATUS']) {
        st += item['STATUS'].toLowerCase();
      }
      if (item['type']) {
        st += item['type'].toLowerCase();
      }
      if (item['department']) {
        st += item['department'].toLowerCase();
      }

      const string2 = filterString.toLowerCase();
      if (st.indexOf(string2) > -1) {
        resultArray.push(item);
      }
    }

    // if(resultArray === []){
    //   return resultArray.push(txt);
    // }else{
      return resultArray;


  }

}
